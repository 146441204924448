import React, {useState, useRef, useEffect} from 'react';
import  {Badge, Tooltip, IconButton, Select, MenuItem, RadioGroup, Radio, FormControlLabel, Typography } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';
import { makeStyles } from "@material-ui/styles";

import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import { PlateEditor } from './plateEditor';
import ResizeObserver from 'react-resize-observer';
import common from '../../services/commonService';
import { VehicleBounding } from '../../data/platesData';

import platesService from '../../services/platesService';






/**
 * editor for plate annotations - contains tag editors
 * handles most editing operations
 * @param props 
 * @returns 
 */
export function VehicleEditor(props: any) {

  const [value, setValue] = useState(0); // integer state
  const canvasRef = useRef(null);

  const ant = props.ant as VehicleBounding;
  // display value for associated bccm
  const bccmIndex = ant.bccmIndex.value || -1;
  const bccm = common.plates.selectedRecord?.bccm?.Annotations[bccmIndex]?.lp_id?.value || '';


  const deletePlateIndex = (index: number) => {
    try {
      ant.plateIndices.value.splice(index, 1);
      ant.plateIndices.status = 'tagged';
    } catch (ex) {
      console.error('failed to delete plate index:', ex);
    }
  }

  const getPlateFromIndex = (index: number) => {
    try {
      const plateNumber = common.plates.selectedRecord?.Annotations[index]?.PlateNumber?.value;
      if (!plateNumber) 
        return `${index+1} - (No plate)`;

      return `${index+1} - ${plateNumber}`;
    } catch (ex) {
      console.error('failed to get plate from index:', ex);
      return 'error';
    }
  }
  /**
   * common change handler
   * @param key 
   * @param value 
   */
  const handleChanges = (key: string, value: any) => {
    try {
      var record = common.plates.selectedRecord;
      switch (key) {
        case 'Pose': 
          ant.pose.value = value;
          ant.pose.status = 'tagged';
          setValue(v => v +1);
          common.notify('VehicleBoudingChanged');
          break;

        case 'VehiclePlateNumberChanged':
          setValue(v => v + 1);
          break;

        case 'DeleteVehicle':
          common.notify('PlatesDeleteVehicle', value);
          break;

        case 'ImageClicked':
          common.unselectAnnotations();
          common.plates.selectedVehicle = ant;
          common.notify('VehicleBoudingChanged');
          break;

        case 'DeletePlateIndex':
          deletePlateIndex(value);
          setValue(v => v + 1);
          break;

        case 'VehicleType':
          ant.VehicleType.value = value;
          setValue(v => v + 1);
          break;

    
      }
    } catch (ex) {
      console.error('failed to handle vehicleEditor changes: ', ex);
    }
  }

  const getCountry = (raw: string) => {
    return raw ? raw.split(' ')[0] : '';
  }

  /**
   * re
   */
  useEffect(() => {
    const subscription = common.notifier$.subscribe(msg => {
      handleChanges(msg.name as string, msg.data);
    });
    return (() => {
      subscription.unsubscribe()});
  }, []);

  useEffect(() => {
    const canvas = (canvasRef.current as any) as HTMLCanvasElement;
    platesService.canvas.setVehicleThumbnail(props.index, canvas);
    return () => {
      platesService.canvas.setVehicleThumbnail(props.index, null);
    };
  });

    /**
   * are there any pretag fields
   * @returns 
   */
     const preTagged = (): boolean => {
      try {
        return  ant?.pose?.status === 'pre-tagged' || 
        ant?.rect.status === 'pre-tagged' || 
        ant?.plateIndices.status === 'pre-tagged';
      } catch (ex) {
        console.error('failed on preTagged', ex);
        return false;
      }
    }

      /**
   * get the border color and width 
   * @returns 
   */
  const getBorder = () => {
    try {
      // WTT-417
      return selected ? "blue solid 2px" : "gray solid 1px" ; 
    } catch (ex) {
      console.error('failed to get border:', ex);
      return "red solid 5px";
    }
  }

  /**
   * render
   */
  const classes = useStyles();
  const tooltips: boolean = true;
  const poseTypes = common.plates?.poseTypes || [];
  const pose = ant?.pose?.value || '';
  const poseStatus = ant?.pose?.status || '';
  const plateIndices = ant?.plateIndices?.value || [];
  const rectStatus = ant?.rect?.status || '';
  const plateIndicesStatus = ant?.plateIndices?.status || '';
  
  const selected = ant === common.plates.selectedVehicle;
  const showDetails = common.plates.newTagType === 'Vehicle';
  
  const vehicleType =ant?.VehicleType?.value || '';

  return ( 
    <Box display="flex" flex="1" flexDirection="column" border={getBorder()} margin="4px" width={showDetails ? "250px" : "120px" } maxWidth="250px" minWidth={showDetails ? "250px" : "120px"} >
        
        <Box display="flex" margin="4px" flexDirection="row">

        <Tooltip title= {tooltips ? "Plate cutout": ''} placement="left">
      <Badge color="error"  variant="dot" 
            anchorOrigin={{horizontal: 'left', vertical: 'top'}}
            badgeContent={rectStatus === "pre-tagged" ? 1 : 0} >
        <canvas  ref={canvasRef} 
        onMouseDown={e => handleChanges('ImageClicked', null) }
          width={100} height={100}   style={{backgroundColor: 'teal', cursor: 'pointer', height:'100px', width:'100px', margin:'4px'}}>
        </canvas>
        </Badge>
        </Tooltip>

        { showDetails && 
          <Box display="flex" margin="4px" flexDirection="column">
          <b>Vehicle pose</b>
          <Badge color="error"  variant="dot" 
            anchorOrigin={{horizontal: 'left', vertical: 'top'}}
            badgeContent={poseStatus === "pre-tagged" ? 1 : 0} >
              <RadioGroup value={pose} onChange={e => handleChanges('Pose', e.target.value)}>
              <FormControlLabel style={{height:'25px'}} value="front" control={<Radio size='small' />} label="Front" />
              <FormControlLabel style={{height:'25px'}}  value="rear" control={<Radio size='small' />} label="Rear" />
              <FormControlLabel style={{height:'25px'}}  value="full" control={<Radio size='small' />} label="Full" />
              <FormControlLabel style={{height:'25px'}}  value="out_of_view" control={<Radio size='small' />} label="Out" />
               </RadioGroup>
          </Badge>
  
         
              </Box>
        
        }

            </Box>

            {showDetails && <Box display="flex" flexDirection="row" marginLeft="10px">
                
              <Tooltip title= {tooltips ? "Plate cutout": ''} placement="left">
        <Badge color="error"  variant="dot" 
              anchorOrigin={{horizontal: 'left', vertical: 'top'}}
              badgeContent={plateIndicesStatus === "pre-tagged" ? 1 : 0} >
          <b>Plates:</b>
          </Badge>
          </Tooltip>

                <Box display="flex" flexDirection="column">

                { plateIndices.map( (pi:number, index:number) => 
          <Box display="flex" flexDirection="column">
            <Box display="flex" flexDirection="row" alignItems="center">
            <Tooltip title="Delete plate association">
            <IconButton onClick={() => handleChanges('DeletePlateIndex', index) } style={{width:'20px', height:'20px'}}><DeleteIcon/></IconButton>
            </Tooltip>
            {getPlateFromIndex(pi)}
            </Box>
          </Box>)}
                </Box>
              
              
              </Box>}

            {showDetails &&  bccm && <Box display="flex" flexDirection="column">
            Bccm:
            <b>{bccm}</b>
            </Box>}

            {showDetails && 
            <Box display="flex" flexDirection="row" alignItems="center" marginLeft="10px">
              <b>Type:</b>
              <Box width="10px"/>
              <Select className={classes.combo} 
          value={vehicleType || 0} 
          onChange={e =>handleChanges("VehicleType", e.target.value)}>
              {common.plates.vehicleTypes?.map((vt:any) => ( <MenuItem value={vt.value}>{vt.name}</MenuItem>))}
        </Select>
              </Box>
            }

            {/* <Box display="flex" flex="1"></Box> */}

          <Box display="flex" flexDirection="row" marginLeft="10px" >
            <Tooltip title={tooltips ? "Delete annotation" : ''}>
            <IconButton  edge="start" 
            onClick = {() =>  common.notify('DeleteVehicle', ant as any)}
              color="primary" >
            <DeleteIcon/>
            </IconButton>
            </Tooltip>

            </Box>


    </Box>

 
 
  )

}

const useStyles = makeStyles({

  radio: {
    margin: 0,
    padding: 0,
    fontSize: 40,
  },
  checkbox: {
    margin: 0,
    padding: 0,
  },
  formControl: {
    margin: 0,
    padding: 0,
    fontSize:40,
  },
  button: {
    margin: 2,
    fontSize: 10,
    color: 'white',
    backgroundColor: 'black',
    '&:hover': {
      backgroundColor: 'gray',
      color: 'black',
  },
  },

  combo: {
    width: 120,
  },

});