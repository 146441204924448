
import common from './commonService';
import { VehicleBounding } from '../data/platesData';
import { isArray, isNumber, isString } from '@material-ui/data-grid';

export class PlatesVehicles {
  

  constructor () {
    try {
      common.notifier$.subscribe(msg => {
        switch(msg.name) {

          case "LaneSettingsChanged":
            case "AnnotationAdded":
            case "AnnotationDeleted":
            case "VehicleBoudingDeleted":
            case "VehicleBoudingChanged":
            case "VehicleBoundingAdded":
            case "PlateNumberChanged":
            case 'VehicleResized':
                this.updateVehicle();
                break;

            case 'PlatesDeleteVehicle':
              this.deleteVehicle(msg.data);
              break;

              


      

        }
      });
    } catch (ex) {
      console.error('failed to construct platesVEhicles:', ex);
    }
  }



  /**
 * returns an array of vehicles from native record
 * @param nativeRecord 
 */
getVehiclesFromNative = (nativeRecord: any) => {
  try {

    const human = nativeRecord?.image_library?.human;
    if (!human)
      return [];

    // ofer 01/02/2022 - WARNING backward compatibility encountered, preserve ? 
    const vehicle = human.vehicle || human.plates?.vehicle;
    if (!vehicle)
      return [];

    // WTT-172
    const vehicleAnnotations = !!vehicle.Annotations ? vehicle.Annotations : vehicle;
    console.assert(Array.isArray(vehicleAnnotations), "Vehicle annotations is not an array");

    const vehicles: VehicleBounding[] = [];
    vehicleAnnotations.forEach((v:any) => {
      const vehicle = new VehicleBounding();
      // backward compat.
      const pose = v.zone || v.pose || '';
      let oldFormat = isString(pose);
      vehicle.pose = oldFormat ? {status: 'tagged', value: pose} : pose;
      // WTT-441
      if (v.VehicleType)
        vehicle.VehicleType = v.VehicleType;

      switch(pose) {
        case 'F':
        case 'front':
          vehicle.pose.value = 'front';
          break;

        case 'R':
        case 'rear':
          vehicle.pose.value = 'rear';
          break;

        case 'full':
          vehicle.pose.value = 'full';
          break;
      } 
      // 31/01/2022
      oldFormat = isArray(v.rect);
      if (isArray(v.rect) && v.rect.length < 4) {
        console.error('vehicle rect error');
        v.rect = [100,100,200,200];
      }
      vehicle.rect = oldFormat ? {status: 'tagged', value: v.rect } : v.rect;
      const annotations = human?.plates?.Annotations;
      const bccm = human?.bccm;
      // modified by user, recalculated when tagging is modified
      // if never set - an empty array
      let plateIndices = v.id_plates || [];
      if (plateIndices === -1)
        plateIndices = [];
      else if (isNumber(plateIndices))
        plateIndices = [plateIndices];
        
      oldFormat = isArray(plateIndices);
      vehicle.plateIndices = oldFormat ? {status: 'tagged', value: plateIndices} : plateIndices;
      // read only, never modified by user
      vehicle.bccmIndex = {status: 'tagged', value: this.getVehicleBccmIndex(bccm, vehicle)};
      vehicles.push(vehicle);
    });
    return vehicles;
  } catch (ex) {
    console.error('failed to get vehicles from native:', ex);
    return [];
  }

}

/**
 * converts app to native
 */
getNativeVehicles = (vehicles: VehicleBounding[]) => {
  try {
    const natives:any = [];
    // common.plates.vehicles.forEach((v:VehicleBounding) => {
      vehicles.forEach((v:VehicleBounding) => {
      const native:any = {};
      // ofer 31/01/2022 WTT-208
      // ofer 01/02/2022 WTT-208 all params are compound
      v.rect.value = v.rect.value.map((x:number) => Math.round(x)); 
      native.rect = v.rect;
      native.zone = v.pose;
      native.id_vehicle = {status: 'tagged', value:0 };
      native.id_plates = v.plateIndices;
      native.id_bccm = v.bccmIndex;
      native.VehicleType = v.VehicleType;
      natives.push(native);
    });
    // WTT-172
    return { Annotations: natives};
  } catch (ex) {
    console.error('failed to get native vehicles:', ex);
    return [];
  }
}

updateVehicle = () => {
  try {
    const rec = common.plates.selectedRecord;
    common.plates.vehicles.forEach((v:VehicleBounding) => {
      const plateIndices = this.getVehiclePlateIndices(rec.Annotations, v);
      // ofer WTT-208 - detect modification in plate indices
      const s1 = JSON.stringify(v.plateIndices.value);
      v.plateIndices.value = plateIndices;
      const s2 = JSON.stringify(v.plateIndices.value);
      if (s2 !== s1)
        v.plateIndices.status = 'tagged';

      // associate bccm tag
      const bccmIndex = this.getVehicleBccmIndex(rec.bccm, v);
      v.bccmIndex = {status: 'tagged', value: bccmIndex};
      // no change control - verify that does not blink
      common.notify('VehiclePlateNumberChanged');
    });
  } catch (ex) {
    console.error('failed to update vehicles:', ex);
  }
}

/**
 * determine if all points are inside the rectangle
 * @param points - x1,y1,x2,y2 ... 
 * @param rect - x0, y0, 
 * @returns 
 */
pointsInRect = (points:number[], rect:number[]): boolean => {
  try {
    for (let i  = 0; i < points.length/2; i++) {
      const x = points[i*2];
      const y = points[i*2 +1];
      if (!this.rectContains(rect, x, y))
        return false;
    }
    return true;
  } catch (ex) {
    console.error('failed on plateContained:', ex);
    return false;
  }
}

/**
 * plate number within vehicle
 * @param plates - plate annotations
 * @param v - il vehicle
 * @returns 
 */
 getVehiclePlateIndices = (plates: any[], v: any): number[] => {
  try {
    if (!plates)
      return [];

    if (!v.rect)
      return [];

    const indices = [];

    for(let i = 0; i < plates.length; i++) {
      const p = plates[i];
      const pts = p.Poly.value.split(',').map((p2:string) => parseInt(p2));
      // if all points are within the rect
      if (this.pointsInRect(pts, v.rect.value))
        indices.push(i);
      }
    return indices;
  } catch (ex) {
    console.error('failed to get vehicle plate:', ex);
    return [];
  }
}

/**
 * get bccm annnotation within the specified vehicle
 * @param plates 
 * @param v 
 * @returns 
 */
getVehicleBccmIndex = (bccm: any, v: any): number => {
  try {
    if (!bccm?.Annotations)
      return  -1;

    if (!v.rect)
      return -1;


    for(let i = 0; i < bccm.Annotations.length; i++) {
      const ant = bccm.Annotations[i];
      const keys = [
        "lpp_top_left_col",
        "lpp_top_left_row",
        "lpp_top_right_col",
        "lpp_top_right_row",
        "lpp_bot_right_col",
        "lpp_bot_right_row",
        "lpp_bot_left_col",
        "lpp_bot_left_row"
      ];
      // ofer 18/10/2021 - WTT-159 include la targhe solo quando è completamente nel rettangolo
      const p = keys.map(k => parseFloat(ant[k]?.value));
      if (this.pointsInRect(p, v.rect)) 
        return i;
      
    }
    return -1;
  } catch (ex) {
    console.error('failed to get vehicle plate:', ex);
    return -1;
  }
}

rectContains = (r: number[], x: number, y: number) => {
  try {
    return r[0] < x && r[0] + r[2] > x && r[1] < y && r[1] + r[3] > y;
  } catch (ex) {
    console.error('failed on rect contains:', ex);
    return false;
  }
}

  /**
   * delete specified vehicle
   * @param vehicle 
   */
   deleteVehicle = (vehicle:any) => {
    try {
      const vehicles = common.plates?.vehicles;
      const index = vehicles?.indexOf(vehicle);
      if (index < 0)
        return;

      vehicles.splice(index,1);
      common.notify('VehicleBoudingDeleted');

    } catch (ex) {
      console.error('failed to delete vehicle:', ex);
    }
  }

}

const platesVehicles: PlatesVehicles = new PlatesVehicles();
export default platesVehicles;