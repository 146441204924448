import React, {useState, useEffect} from 'react';
import {Box, FormControlLabel, Checkbox} from '@material-ui/core'
import SplitterLayout from 'react-splitter-layout';
import 'react-splitter-layout/lib/index.css';
import ResizeObserver from 'react-resize-observer';
import common from '../../services/commonService';
import { Thumbnails } from './thumbnails';
import { CanvasWrapper } from './canvasWrapper';
import { AxleTabs} from './axleTabs';
import { Stitched } from './stitched';
import { TagProps } from './tagProps';
import { ToolsPanel } from './toolsPanel';
import { ImageNav } from './imageNav';
import { AuditPanel } from './auditPanel';
import { StitchProps } from './stitchProps';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}



export function AxlesDivider(props: any) {

  const [value, setValue] = useState(0); // integer state

/**
 * general callback
 * @param arg 
 * @param value 
 */
const handleCallback = (key: string, value: any) => {
  try {
      switch(key) {
        case 'StitchedUrlChanged':
        case 'AxlesOverlapModeChanged':
        case "AxlesStitchMode":
          setValue(v => v + 1);
          break;
      }
    
  } catch (ex) {
    console.error('failed to handle callback: ', ex);
  }
}

const handleStitchChanged = (checked: boolean) => {
  try {
    // only if both stitched and single images exist
    if (!common.axles.overlapAllowChange)
      return;
    common.axles.overlapMode = !checked;
    setValue(v => v + 1);
    common.notify('AxlesOverlapModeChanged');
  } catch (ex) {
    console.error('failed to handle stitch changed:', ex);
  }
}

/**
 * subscribe to global notifications
 */
useEffect(() => {
  const subscription = common.notifier$.subscribe(msg => {
    handleCallback(msg.name as string, msg.data);
  });
  return (() => {
    subscription.unsubscribe()});

}, []);

const { height, width } = useWindowDimensions();
const stitchedUrl = common.axles.stitchedUrl || '';
const stitched = !common.axles.overlapMode;
// rendering
return ( 
  <Box display="flex" flex="1" flexDirection="column" style={{background:'whitesmoke'}}>

        <Box display="flex" flex="1" flexDirection="column" width={width-330 - 60} maxWidth={width- 330 - 60} height={height - 50}  >

          <Box display="flex" flex="1" height={height - 50 - 230} flexDirection="row">


            <Box width ={width-330 - 60 - 400} height={height-50-230-5}  >
              <CanvasWrapper width={width-330 - 60 -400} height={height-50-230-5}></CanvasWrapper> 
            </Box>

            <Box display="flex" flexDirection="column" width={400} height={height-50-230-5}  >
              <AxleTabs width={400} height={height-50-230-5 - 30}></AxleTabs>
              <ToolsPanel height='30px'></ToolsPanel>
            </Box> 
       
            
            </Box>

            <Box display="flex"  height="5px" style={{background:'gray'}}></Box>

            {!common.axles.overlapMode && 
             <Box display="flex" flexDirection="row" height="230px" width={width - 335} >

              <Box display="flex" flexDirection="column" width='300px' maxWidth='300px'>
                <StitchProps/>
              {/* <TagProps style={{width:'300px',maxWidth:'300px'}} width={300}></TagProps>  */}
              </Box>

             <Box display="flex" flexDirection="column" flex="1" width={width - 335 - 300} >

               <Box display="flex" flexDirection="column" flex="1" height={230 - 30} minHeight={230 - 30} >
                 <Box display="flex" flexDirection="row">
                   <Box display="flex" height={230 - 30} width={width - 395 - 300 - 120} style={{background:'lightgray'}}
                    maxWidth={width - 395 - 300 - 120} flexDirection="column">
                   {stitchedUrl && 
                     <img style={{height:'100%',width:'100%', objectFit:'contain'}} src={stitchedUrl}></img>
                   } 
                  </Box>
                  <Box display="flex" flexDirection="column" width="120px" padding="4px" style={{}}>
                  <FormControlLabel 
              control={<Checkbox size="small" checked={stitched} 
              onChange={ e => handleStitchChanged( e.target.checked)}/>} label="Stitched" />
                  </Box>

                </Box>
                </Box>
           
             </Box>
           </Box>
            }


            {common.axles.overlapMode && 
              <Box display="flex" flexDirection="row" height="230px" width={width - 335}>

              <Box display="flex" flexDirection="column" width='300px' maxWidth='300px' minWidth='300px'>
              <TagProps style={{width:'300px',maxWidth:'300px'}} width={300}></TagProps>
              </Box>
              <Box display="flex" flexDirection="column" flex="1">
              <Stitched width={width-390 - 300} height={200}></Stitched>
              <ImageNav style={{height:'20px'}}></ImageNav>
              </Box>
            </Box>
            }

  
          

            </Box>


   

  
  </Box>
)

}