import React, {useState} from 'react';
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import common from '../../services/commonService';
import {Box, Select, Button, MenuItem, Checkbox, RadioGroup, Radio,
  FormControlLabel, IconButton, Tooltip, Typography } from '@material-ui/core'
import { Check as CheckIcon, ErrorOutline as ErrorIcon } from '@material-ui/icons';

export function StitchProps(props: any) {

    // eslint-disable-next-line
  const [value, setValue] = useState(0); 


  const handleChanges = (key: string, value: any = null) => {
    try {
      switch(key) {
          // case 'Passed':
          //     axles.auditStatus = 'passed';
          //     common.notify('AxlesSaveSequenceAndNext');
          //     break;

          //   case 'Failed':
          //       axles.auditStatus = 'failed';
          //       common.notify('AxlesSaveSequenceAndNext');
          //       break;

          case 'AxlesStitchedModifiedChanged':
            setValue(v => v + 1);
            break;


              case 'deprecation':
                setDeprecation(value);
                setValue(v => v + 1);
                break;

              case 'Save':
                common.notify('AxlesSaveStitched');
                break;

              case 'Cancel':
                common.notify('AxlesCancelStitched');
                break;

   
      }

    } catch (ex) {
      console.error('failed ')
    }
  }

  React.useEffect(() => {
    const subscription = common.notifier$.subscribe (msg => {
      handleChanges(msg.name as string, msg.data);
    });
    return (() => {
      subscription.unsubscribe();
    });
  }, []);

  /**
   * set deprecation value
   * @param value 
   */
  const setDeprecation = (value:string) => {
    try {
      const human = axles?.stitchedInfo?.stitched?.human;
      common.assert(human, "no human section");
      switch(value) {
        case "notTagged":
          delete human.deprecated;
          break;

        case "notDeprecated":
          human.deprecated = false;
          break;

        case 'deprecated':
          human.deprecated = true;
          break;
      }
      const changed = JSON.stringify(axles.stitchedInfo) !== axles.stitchedCached;
      axles.stitchedModified = changed;

    } catch (ex) {
      console.error('failed to set value:', ex);
    }

  }

  const getDeprecation = () => {
    try {
      if (!axles) return undefined;
      const deprecated = axles?.stitchedInfo?.stitched?.human?.deprecated;
      if (deprecated === undefined) return 'notTagged';
      if (deprecated === false) return 'notDeprecated';
      if (deprecated === true) return 'deprecated';
      return 'notTagged';
    } catch (ex) {
      console.error('failed to get deprecation');
    }
  }

  const classes = useStyles();
  const axles = common.axles;




  return <Box display="flex" flexDirection="column" flex="1" width="300px" minWidth="300px" margin="4px">
        <Box height="10px"/>

        <Typography>Deprecation:</Typography>


        <RadioGroup aria-label="dep" name="dep" value={getDeprecation()} onChange={e => handleChanges("deprecation", e.target.value)}>
    <FormControlLabel value="notTagged"  control={<Radio size="small" />} label="Not tagged" style={{height:'20px'}} />
    <FormControlLabel value="notDeprecated"  control={<Radio size="small" />} label="Not deprecated" style={{height:'20px'}} />
    <FormControlLabel value="deprecated"  control={<Radio size="small" />} label="Deprecated" style={{height:'20px'}} />
    </RadioGroup>


        

    <Box display="flex" flex="1"></Box>

{common.axles.stitchedModified && <Typography style={{fontWeight:"bold"}}>Stitched data modified, save or cancel:</Typography>}

    

    <Box display="flex" flexDirection="row"  alignItems="center">

<Tooltip title='Commit and move to next image' placement='bottom'>
          <span>
            <Button className={classes.button} style={{marginTop: 5}} 
              disabled={!common.axles.stitchedModified}
              onClick={() => handleChanges('Save', null)} variant="contained">Save</Button>
          </span>
        </Tooltip>

     <Tooltip title='Reload image, cancel changes' placement='bottom'>
       <span>
        <Button className={classes.button} style={{marginTop: 5}}
        disabled={!common.axles.stitchedModified} 
        onClick={() => handleChanges('Cancel', null)} variant="contained">Cancel</Button>
        </span>
        </Tooltip> 



  </Box>


    
</Box>



}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: 2,
    fontSize: 9,
    color: 'white',
    backgroundColor: 'black',
    '&:hover': {
      backgroundColor: 'gray',
      color: 'black',
  },
  },
  combo: {
    width: 80,
  },
}));
